<script>
    export let data;
    export let currentTab = "jobdescription";

    import Header from '@/components/job/Header.svelte';
    import Navigation from '@/components/job/Navigation.svelte';
    import JobDescription from '@/components/job/JobDescription.svelte';
    import Benefits from '@/components/job/Benefits.svelte';
    import About from '@/components/job/About.svelte';
    import ContactPerson from '@/components/job/ContactPerson.svelte';
    import ApplyPopover from '@/components/job/ApplyPopover.svelte';

    import { jobAdvertisement as tempJobAdvertisement, employer as tempEmployer } from '@/stores/temporary';
    import { getQueryParameterByName, stickyUntilElement } from '@/lib/utils';
    import { navigate } from 'svelte-routing';
    import { onMount, onDestroy, tick } from 'svelte';

    let id = getQueryParameterByName("id");
    let mode = "view";
    let applyOpen = false;

    if($tempJobAdvertisement?.data === undefined || $tempEmployer?.data === undefined) {
        id ? window.location.href = `/dashboard/job/${id}` : window.location.href = `/404`
    }

    const watchStickyNavbar = () => stickyUntilElement("end-preview", "footer");

    onMount(() => {
        if($tempJobAdvertisement?.data && $tempEmployer?.data) {
            window.addEventListener('scroll', watchStickyNavbar);
        }
    });

    onDestroy(() => {
        window.removeEventListener('scroll', watchStickyNavbar);
    });
</script>

{#if $tempJobAdvertisement?.data && $tempEmployer?.data}
    <main class="mb-32 mt-20">
        <Header mode={mode}/>

        <Navigation 
            mode={mode}
            currentTab={currentTab} 
            tabs={[
                {title: "Jobbeschreibung", value: "jobdescription"},
                {title: "Benefits", value: "benefits"},
                {title: "Über Uns", value: "about"},
            ]}
            on:changeCurrentTab={async (e) => {
                currentTab = e.detail
                await tick();
                scrollTo(0, 0);
            }}
        />

        <div class="container mx-auto max-w-3xl">
            {#if currentTab === "jobdescription"}
                <JobDescription mode={mode}/>
            {/if}

            {#if currentTab === "benefits"}
                <Benefits mode={mode}/>
            {/if}

            {#if currentTab === "about"}
                <About mode={mode}/>
            {/if}

            <section class="py-6 max-w-sm mx-auto">
                <button on:click={() => applyOpen = true} class="button gradient">
                    Bewirb dich jetzt
                </button>
            </section>

            <ContactPerson mode={mode}/>
        </div>

        <div class="fixed bottom-0 left-0 right-0 p-4 z-50 bg-moss/30 backdrop-blur-md flex justify-center" id="end-preview">
            <div class="flex flex-col gap-4 w-full max-w-sm">
                <button on:click={() => navigate(`/dashboard/job/${id}`)} class="button moss !py-3">
                    Vorschau beenden
                </button>
            </div>
        </div>

        <ApplyPopover open={applyOpen} previewMode={true} on:close={() => applyOpen = false}/>
    </main>
{/if}